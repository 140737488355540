<div
  class="results-value"
  (click)="onItemClick()"
  [class.selected]="isItemSelected"
>
  <div class="result-item">
    <fc-user-avatar
      [employee]="result"
      [size]="28"
      [fontSize]="14"
    ></fc-user-avatar>

    <div class="result-desc">
      <div class="result-title" [innerHTML]="name"></div>
      @if (result.primaryPhoneNumber !== 'None') {
        <div class="result-subtitle">
          {{ result.primaryPhoneNumber }}
        </div>
      }
    </div>
  </div>
</div>
